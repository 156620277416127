import React from "react";
import "../../style/Lytx/FormLytx.css"
import Surfsight from '../../image/Lytx-surfsight.jpg';
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";
import distracao from '../../image/distraido.jpg';
import monitoramento from '../../image/monitoramento.png';

import {listaD} from "react-router-dom";


export default () => {
  const navigate = useNavigate();

  function enviarEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_bb7d8nr', 'template_6u0rja7', e.target, 'HnXf-XsEtn1vy5JpU')
      .then((result) => {
          navigate("/FormularioEnviado");
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  }
  function listaD(){ 
    
    const h2 = document.getElementsByClassName('text-info-lytx')
    
    h2.addEventListener('click', (e) =>{
      console.log(e)
    })
    
    
    
  }
  return(
    <div>
        <div className="Lytx-introducao">
        <div className="sobre-Form">
          <h3>Sistema de Video Monitoramento</h3>
          <img src={Surfsight} alt="" />
        </div>
        
        </div>
      <div className="">

      </div>

      
      <div className="demo">
        <h3>Garanta seu equipamento em comodato</h3>
      </div>
      <form className="form-geotab" onSubmit={enviarEmail}>
          <div className="informacoes-Geotab">
            <div style={{
              display: "flex"
            }}>
              <div className="col-info-Geotab">
                <p>Nome*</p>
                <input
                style={{
                  width: "90%"
                }}
                type="text" className="form-control" required placeholder="Digite seu nome" name="name"/>
              </div>
              <div className="col-info-Geotab">
                <p>Sobrenome*</p> 
                <input
                style={{
                  marginRight: 40,
                  width: "90%"
                }}
                type="text" className="form-control" required placeholder="Digite seu Sobrenome" name="LastName"/>
              </div>
            </div>
              <div className="col-info-Geotab">
                  <p>Empresa*</p>
                  <input type="text" className="form-control" required name="empresa" placeholder="Empresa" />
              </div>
              <div className="col-info-Geotab">
                  <p>Email*</p>
                  <input type="email" className="form-control" required name="email" placeholder="Digite seu email" />
              </div>
              <div className="col-info-Geotab">
                  <p>Tamanho da frota*</p>
                  <input type="number" className="form-control" required name="frota" placeholder="Digite o tamanho da frota" />
              </div>
              <div className="col-info-Geotab">
                  <p>Telefone*</p>
                  <input type="tel" required className="form-control" name="phone" placeholder="Digite o numero de telefone" />
              </div>
              <div className="col-info-Geotab">
                <p>Cidade*</p>
                <input type="text" required className="form-control" name="cidade" placeholder="Cidade" />
              </div>
              <p className="info-cliente">*Sujeito a análise de crédito</p>
              <div className="botao-Geotab">
                <input type="submit" className="btn-envio" value="Enviar Mensagem"/>
              </div>
          </div>
        </form>
    </div>
        
    
  )
}