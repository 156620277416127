import React from "react";
import "../style/Home/InfoHome.css";


export default () => {
  return(
    <div className="about-us">
      <div className="text-about-us">
        <h1>Nossa Historia</h1>
        <p>Fundada na década de 80, a Ariasat atua no segmento de Telemetria Satelital desde 1999. Foi a primeira empresa brasileira a disponibilizar mapas digitalizados via internet para seus clientes. A parceria com a Orbcomm, empresa Norte-Americana, permite a utilização de satélites de baixa órbita (LEO) para serviços de telemetria e comunicação máquina-a-máquina (M2M). Os satélites (LEO) de baixa órbita, são confiáveis e tem baixo custo de manutenção, possibilitando a coleta de dados de telemetria em locais de difícil acesso. Procurando melhor atender os clientes, a Ariasat firmou acordo com a Globalstar, passando a representar as mais novas soluções para telefonia e comunicação de dados via satélite.</p>
        <p>A Ariasat atualmente oferece soluções para gestão e rastreamento de frotas com produtos plug-n-play acompanhados de uma plataforma online própria para gestão dos ativos e monitoramento em tempo real de veículos.</p>
      </div>
    </div>
  )
}