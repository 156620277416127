import React from "react";
import "../style/Home/Footer.css";

import {GrInstagram, GrLinkedin, GrFacebook} from 'react-icons/gr';


export default () => {
  return(
    <div class="container">
      <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <ul className="contato-footer">
            <li className="number-1"><a href="tel:+551128772000">(11) 2877-2000</a></li>
            <li className="number-2"><a href="tel:+5511986357717">(11) 98193-6641</a></li>
          </ul>
        <div class="col-md-4 d-flex align-items-center justify-content-center">
          <span class=" mb-3 mb-md-0 text-muted justify-content-center align-items-center">&copy; 2022 Copyright, Ariasat</span>
        </div>
       
        <ul class="list-unstyled d-flex">
          <li class="ms-3"><a class="text-muted" href="https://www.instagram.com/ariaasatt/" target="blank"><GrInstagram/></a></li>
          <li class="ms-3"><a class="text-muted" href="https://www.linkedin.com/company/ariasat/" target="blank"><GrLinkedin/></a></li>
          <li class="ms-3"><a class="text-muted" href="https://www.facebook.com/profile.php?id=100063897311867" target="blank"><GrFacebook/></a></li>
        </ul>
       
      </footer>
      
</div>
  )
}