import React from "react";
import "../../style/Preps/porqueUtilizar.css"
import preps from "../../image/img-preps.jpeg"

export default () => {
  return(
    <div className="why-use-preps">
    
      <div className="why-use-preps-text">
        
        <h4>Pescarias</h4>
        <p>Acompanhem periodicamente a posição.
           Em caso de naufrágio ou pane mecânica, sua última posição ficará registrada em nosso sistema.</p>
      </div>

      <div className="why-use-preps-text"> 
        <h4>Botão de Panico</h4>
        <p>Em caso de emergência o pescador pode acionar o botão o vermelho que emite um sinal de panico e pode que pode sinalizar problema no motor</p>
      </div>
    </div>
  );

}