import React from "react";
import "../../style/geotab/formGeo.css";
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";

export default () =>{
  
  const navigate = useNavigate();

  function enviarEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_bb7d8nr', 'template_6u0rja7', e.target, 'HnXf-XsEtn1vy5JpU')
      .then((result) => {
          navigate("/FormularioEnviado");
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  }

  return(
    <div>
      <div className="demo">
        <h4>Entenda mais sobre nossos serviços</h4>
      </div>
      <form className="form-geotab" onSubmit={enviarEmail}>
  <div className="informacoes-Geotab">
    <div className="col-info-Geotab">
      <p className="comodato"> Agora em comodato </p>
      <p>Nome*</p>
      <input
        style={{ width: "90%" }}
        type="text"
        className="form-control"
        required
        placeholder="Digite seu nome"
        name="name"
      />
    </div>
    <div className="col-info-Geotab">
      <p>Sobrenome*</p>
      <input
        style={{ width: "90%" }}
        type="text"
        className="form-control"
        required
        placeholder="Digite seu Sobrenome"
        name="LastName"
      />
    </div>
    <div className="col-info-Geotab">
      <p>Empresa*</p>
      <input
        type="text"
        className="form-control"
        required
        name="empresa"
        placeholder="Empresa"
      />
    </div>
    <div className="col-info-Geotab">
      <p>Email*</p>
      <input
        type="email"
        className="form-control"
        required
        name="email"
        placeholder="Digite seu email"
      />
    </div>
    <div className="col-info-Geotab">
      <p>Tamanho da frota*</p>
      <input
        type="number"
        className="form-control"
        required
        name="frota"
        placeholder="Digite o tamanho da frota"
      />
    </div>
    <div className="col-info-Geotab">
      <p>Telefone*</p>
      <input
        type="tel"
        required
        className="form-control"
        name="phone"
        placeholder="Digite o número de telefone"
      />
    </div>
    <div className="col-info-Geotab">
      <p>Cidade*</p>
      <input
        type="text"
        required
        className="form-control"
        name="cidade"
        placeholder="Cidade"
      />
    </div>
    <div className="info-adicional">
      * Sujeito a análise de crédito
    </div>
    <div className="botao-Geotab">
      <input type="submit" className="btn-envio" value="Enviar Mensagem" />
    </div>
  </div>
</form>
    </div>
  )
}