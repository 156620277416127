import React from "react";
import '../style/Contato/Formulario.css';
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie-consent";

import {BsFillTelephoneFill} from 'react-icons/bs';
import {IoLogoWhatsapp} from 'react-icons/io';
import {MdEmail} from 'react-icons/md';
import Contact_us from '../image/Contact-us.png';

export default () => {

  const nome = document.getElementsByName("name");
  const email = document.getElementsByName("email");
  const sobrenome = document.getElementsByName("sobrenome");

  function gravarDados() {
    localStorage.setItem("Nome" , nome.value );
    localStorage.setItem("Sobrenome" , sobrenome.value );
  }
  
  let conf={
  expires: 100,
  sameSite: 'strict',
  domain: ''
  };


  function gravarCookie(){
    Cookies.set("nome", nome.value,conf);
    Cookies.set("sobrenome", sobrenome.value,conf);
    Cookies.set("email", email.value,conf);
  }
  
  const navigate = useNavigate();

  function enviarEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_bb7d8nr', 'template_8uj5gl6', e.target, 'HnXf-XsEtn1vy5JpU')
      .then((result) => {
          navigate("/FormularioEnviado");
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  }
 
  return(
    <div>
      <div className="container-form">
        <div>
          
        </div>
        <form onSubmit={enviarEmail}>
          <div className="informacoes">
              <div className="col-info">
                <p>Nome</p>
                <input type="text" className="form-control" required placeholder="Nome" name="name"/>
              </div>
              <div className="col-info">
                <p>Sobrenome</p>
                <input type="text" className="form-control" required placeholder="Sobrenome" name="LastName"/>
              </div>
              <div className="col-info">
                  <p>Email</p>
                  <input type="email" className="form-control" required name="email" placeholder="Digite seu email" />
              </div>
              <div className="col-info">
                  <p>Nome da Empresa</p>
                  <input type="Nome da Empresa" className="form-control" required name="Nome da Empresa" placeholder="Empresa" />
              </div>
              <div className="col-info">
                  <p>Telefone</p>
                  <input type="tel" required className="form-control" name="phone" placeholder="(XX) XXXXX-XXXX" />
              </div>
              <div className="col-info">
                  <p>Produtos</p>
                  <select name="produtos" className="servicos">
                    <option value="" disabled selectedg>Selecione</option>
                    <option value="Geotab">Geotab</option>
                    <option value="Camera">Camera</option>
                    <option value="Telemetria">Telemetria</option>
                  </select>
              </div>
              <div className="col-info">
                <p>Mensagem</p>
                <textarea placeholder="Digite sua mensagem" name="mensagem" className="form-control" required cols="30" rows="2"></textarea>
              </div>
              <div className="col-info">
                <input type="submit" className="btn-envio" value="Enviar Mensagem" onClick={gravarCookie() && gravarDados()}/>
              </div>
          </div>
          <div className="info-adicionais-form">
                <h1>Vamos manter contato!</h1>
                <div className="main-infoCont-geral">
                  <div className="menu-info-adicionais">
                    <div className="icon-contato">
                      <BsFillTelephoneFill/>
                    </div>
                    <p>Telefone: <a href="tel:+551128772000">(11) 2877-2000</a></p>
                  </div>
                  <div className="menu-info-adicionais">
                    <div className="icon-contato">
                      <IoLogoWhatsapp/>
                    </div>
                    <p>WhatsApp: <a target="blank" href="https://api.whatsapp.com/send?phone=5511986357717&text=Ol%C3%A1%2C%20Gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20seus%20produtos.">(11) 98193-6641</a></p>
                  </div>
                  <div className="menu-info-adicionais">
                    <div className="icon-contato">
                      <MdEmail/>
                    </div>
                    <p>Email: <a href="mailto:suporte@ariasat.net.br">suporte@ariasat.net.br</a></p>
                  </div>
                </div>
          </div>
        </form>
        <div className="mensagem-form">
          <img src={Contact_us} alt="" />
          <h1>Fale com o nosso time para tirar suas duvidas!</h1>
          <p>Nos envie uma mensagem, entraremos em contato o via WhatsApp ou telefone.</p>
        </div>
      </div>
    </div>
  )
}