import React from "react";
import "../../style/Lytx/bannerLytx.css"

export default () => {
  return(
    <div>
      <div className="banner-lytx">
        <h2>Câmera com inteligência artificial Lytx</h2>
      </div>
    </div>
  )
}