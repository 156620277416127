import React from "react";
import "../style/FormEnvio/sucessoEnvio.css"

import ManterContato from "../image/ManterContato.png";

export default () =>{
  return(
    <div className="main-Envio-form">
      <div className="Envio-form">
        <h1>O formulario foi enviado com sucesso! :)</h1>
        <p>Um de nossos colaboradores entrara em contato com você em ate 3 dias uteis.</p>
        <button><a href="/">Voltar para home</a></button>
      </div>
      <div className="img-enviado">
        <img src={ManterContato} alt=""/>
      </div>
    </div>
  )
}