import React from "react";

import Header from "../components/Header";
import AcessoLogin from "../components/AreaUsuario/acessoLogin";
import Footer from "../components/Footer"

import "../style/AreaUsuario/AreaUsuario.css"


function areaUsuario(){
  return(
    <div className="areaUsuario">
      <Header/>
      <AcessoLogin/>
      <Footer/>
    </div>
  )
}
export default areaUsuario;