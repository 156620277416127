import React from "react";
import "../../style/Lytx/infoLytx.css";

export default () => {
  return(
    <div className="about-us-lytx">
      <div className="texto-about-lytx"> 
        <p>A câmera que captura a direção e o comportamento do motorista em tempo real.
        Alimentado por IA e Visão de Máquina, o Lytx detecta e alerta automaticamente o comportamento distraído do motorista e incidentes de direção severos em tempo real, abordando as principais causas de acidentes evitáveis.Equipado com câmeras de alta definição voltadas para a estrada e para a cabine, o Lytx captura a direção, simultaneamente fornecendo às frotas visualização de vídeo sob demanda e recuperação de evidências de vídeo no painel do Geotab.Quase acidentes, colisões, eventos severos de direção, danos e roubo de carga, agressões de motoristas e passageiros são automaticamente detectados, registrados e carregados automaticamente para a nuvem.</p>
      </div>
    </div>
  )
}