import React from "react";
import "../../style/Lytx/RecursoBeneficio.css"

import Surfsight from '../../image/sursight.jpg';

export default () => {
  return(
    <div>
      <div className="main-recursos-beneficios">
        <h2>Recursos e beneficios</h2>
        <img src={Surfsight} alt="" />
        <div className="itens-recursos-beneficios">
          <div className="recursos">
            <h3>Recursos</h3>
            <ul>
              <li>Câmeras de alta definição voltadas para a estrada e para a cabine</li>
              <li>IR para visão noturna clara na cabine</li>
              <li>Sensores e câmeras integrados alimentados por IA e Visão de Máquina</li>
              <li>Alto-falante embutido e tela para treinamento de áudio e visual</li>
              <li>Recuperações de vídeo sob demanda e visualização ao vivo da nuvem Geotab</li>
              <li>Transforme qualquer regra Geotab em um evento de vídeo</li>
              <li>Otimize o vídeo simultaneamente da estrada e do motorista</li>
              <li>Veja até 10 minutos de vídeo ao vivo por mês por dispositivo através do programa Order Now</li>
            </ul>
          </div>
          <div className="beneficios">
            <h3>Beneficios</h3>
            <ul>
              <li>Melhore a segurança com alertas em tempo real de incidentes de direção severos</li>
              <li>Reduza acidentes com IA detectando motoristas distraídos</li>
              <li>Reduza sinistros e custos de seguro com evidências de vídeo ao vivo armazenadas na nuvem</li>
              <li>Melhore a visibilidade da operação com recuperação de imagens de vídeo sob demanda</li>
              <li>Envolvendo os funcionários na melhoria da segurança com dados sobre o comportamento ao dirigir</li>
              <li>Forneça assistência instantânea e avalie os danos em campo em tempo real com vídeo ao vivo</li>
              <li>Treinamento de motoristas personalizado com base no estilo de direção do motorista com dados sobre cada motorista</li>
              <li>Integrado perfeitamente com a plataforma de nuvem Geotab, exibindo o vídeo do veículo ao seu alcance</li>
              <li>Instalação do veículo em 10 minutos</li>
            </ul>
          </div>
        </div>
       <div className="button-lytx">
          <a href="/Contato">Entre em Contato Conosco</a>
        </div>
      </div>
    </div>
  )
}