import React from "react";

import Header from '../components/Header';
import BannerTelemetria from "../components/Telemetria/BannerTelemetria";
import InfoTelemetria from "../components/Telemetria/infoTelemetria";
import ServicosTelemetria from "../components/Telemetria/servicosTelemetria";
import Footer from "../components/Footer";
import OutraSolucoes from "../components/Telemetria/outraSolucoes";

export default () => {
  return(
    <div>
      <Header/>
      <BannerTelemetria/>
      <InfoTelemetria/>
      <ServicosTelemetria/>
      <OutraSolucoes/>
      <Footer/>
    </div>
  )
}