import React from "react";
import "../style/Cookie.css";

import CookieIcon from "../image/CookieIcon.png";
import { useState } from "react";

export default () => {

  const[showBox, setShowBox] = useState(true);

  function click(){
    document.cookie =  "CookieBy=Ariasat; max-age=" + 60 *60*24 * 30;
    if(document.cookie){
      setShowBox(false);
    }else{
      alert("Cookie not set")
    }
  }

  window.onload = () =>{
    let checkCookie = document.cookie.indexOf("CookieBy=Ariasat");
    if(checkCookie){
      setShowBox(false)
    }else{
      setShowBox(true);
    }
  }

  return(
    <div>
      <div className={showBox ? 'wrapper' : 'wrapper hide'}>
        <img src={CookieIcon} alt="" />
        <div className="content">
          <header>Cookie</header>
          <p>Esse site usa cookies. Veja nossa <a href="/politicas">Politica de privacidade</a>.</p>
        </div>
        <div className="btn-Cookie">
          <button onClick={click}>Ok, entendido!</button>
        </div>
      </div>
    </div>
  )

 
}