import React from "react";
import "../../style/geotab/infoGeotab.css";

export default () => {
  return(
    <div>
      <div className="conteudo-info">
        <div className="caixa-info">
          <div className="componentes-info">
            <p>Revolucione sua frota com o Geotab GO9. Uma das soluções telemáticas líderes do setor, este dispositivo pequeno e poderoso oferece uma rica coleta de dados por meio de rastreamento por GPS, IA, diagnóstico avançado e monitoramento do comportamento do motorista.
Projetado para uso fácil em quase todos os veículos, o GO9 oferece a simples tecnologia Plug & Play. Conecte o dispositivo diretamente à porta OBDII do seu veículo ou use um adaptador para uso em veículos sem porta. Não são necessárias ferramentas especiais ou experiência profissional em instalação. Basta conectá-lo e começar a melhorar a maneira como sua frota opera.
O suporte a veículos elétricos traz todos os veículos - e todo tipo de veículo - para uma visão abrangente e única dos dados da sua frota. À medida que o design de sua frota e veículo for alterado, a Geotab continuará fornecendo a telemática de que você precisa.</p>
          </div>
        </div>
      </div>
    </div>
  )
}