import React from "react";

import bannerTelemetria from '../../image/telemetria.jpg';

import "../../style/Telemetria/bannerTelemetria.css";

export default () => {
  return(
    <div>
      <div className="bannerTelemetria">
        <img src={bannerTelemetria} alt="" />
      </div>
    </div>
  )
}