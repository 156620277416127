import React from "react";

import "../../style/geotab/graficosInfo.css"

export default () => {
  return(
    <div>
      <div className="main-graficos">
        <div className="component-grafico">
          <h4>+ de 133 milhões</h4>
          <p>de quilômetros monitorados diariamente</p>
        </div>
        <div className="component-grafico">
          <h4>+ de 2 milhões</h4>
          <p>de veiculos em operação por mês</p>
        </div>
        <div className="component-grafico">
          <h4>+ de 40 bilhões</h4>
          <p>de dados coletados diariamente</p>
        </div>
      </div>
    </div>
  )
}