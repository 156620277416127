import React from "react";
import "../../style/Telemetria/infoTelemetria.css";

export default () => {
  return(
    <div className="about-us-telemetria">
      <div className="texto-about-telemetria">
        <h3>Rastreador via satélite</h3>
        <p>Telemetria é uma tecnologia que permite comunicação através redes sem fio. Na telemetria podemos medir e fornecer diversos dados referentes ao desempenho de maquinas, meteorologia, eficiência energética entre outros. A Ariasat Brasil oferece inúmeras soluções em telemetria via satélite como:</p>
      </div>
    </div>
  )
}