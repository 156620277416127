import React from "react";
import "../../style/Preps/infoPreps.css";

export default () => {
  return(
    <div className="about-us-preps">
      <div className="texto-about-preps">
        <p>Homologado pelo ministério da pesca (Preps) Em 04 de setembro de 2006 o Ministério da Pesca e Aquicultura- MPA instituiu e regulamentou o programa nacional de rastreamento de embarcações-Preps, visando o controle a preservação de áreas protegidas e a segurança dos barcos. A Ariasat é homologada pelo ministério da pesca a implementar o sistema Preps em embarcações pesqueiras acima de 15 metros.</p>
      </div>
    </div>
  )
}