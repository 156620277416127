import React from "react";


import BannerGeo from "../components/geotabscreen/bannerGeo";
import FormularioGeo from "../components/geotabscreen/formularioGeo";
import GraficosInfo from "../components/geotabscreen/graficosInfo";
import InfoGeotab from "../components/geotabscreen/infoGeotab";
import Recursos from "../components/geotabscreen/recursos";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default () => {
  return(
    <div>
      <Header/>
      <BannerGeo/>
      <InfoGeotab/>
      <GraficosInfo/>        
      <Recursos/>
      <FormularioGeo/>
      <Footer/>
    </div>
  )
}