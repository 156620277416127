import React from "react";

import Header from "../components/Header";
import PoliticasComp from "../components/PoliticasComp";
import Footer from "../components/Footer";

export default () => {
  return(
    <div>
      <Header/>
      <PoliticasComp/>
      <Footer/>
    </div>
  )
}