import React from "react";
import "../../style/Preps/comoFuncionaPreps.css"
import preps from "../../image/img-preps.jpeg"
import "../../style/Preps/dispositivo.css"
export default () => {
  return(
    <div>
      <div className="main-cont-preps">
        <div className="text-main-cont-preps">
          <h4>Como funciona</h4>
          <p>O barco manda informações a cada hora para um dos satélites globais através de um sistema de localização GPS. Esse satélite retorna as informações para o órgão marítimo responsável que por sua vez irá monitorar sua embarcação.
          
          Havendo algum imprevisto poderá ser acionado o botão pânico que informa que a embarcação possui alguma anormalidade.</p>    
        </div>     

        <img src="https://img.yumpu.com/14696933/1/500x640/cartilha-preps-11-de-junho-sinpesq.jpg" width={"300px"} />  
      </div>
      <div className="why-product">
      </div>
    </div>
  );
}