import React from "react";

import "../../style/Telemetria/servicosTelemetria.css";

export default () => {
  return(
    <div>
      <div className="servicos-telemetria">
        <div className="m2m">
          <img src="https://geotab.ariasat.net.br/images/icons/maquinaamaquina.png" alt="" />
          <h4>M2M - Máquina a Máquina</h4>
          <p> Soluções Machine-to Machine (M2M) são tecnologias que dão capacidade a sistemas, de se comunicarem com outros dispositivos, para assim ter controle ou visualização de indicadores.
              O serviço M2M permite o trafego de dados via satélite para interligar sistemas e dispositivos remotos, de forma eficaz e econômica e assim possibilitar o funcionamento de soluções de segurança, rastreamento, telemetria, telecomando, etc.
              O funcionamento do serviço M2M Ariasat tem 100% de cobertura no Brasil.</p>
        </div>
        <div className="agricultura">
          <img src="https://geotab.ariasat.net.br/images/general/meteo-1.jpg" alt="" />
          <h4>Agricultura / Meteorologia</h4>
          <p>Telemetria utilizada na prevenção de doenças e na correta irrigação, estas estações retornam à estação base os principais parâmetros necessários para tomada das melhores decisões:
            <br />
            - Temperatura do ar e umidade relativa;
            <br />
            - Precipitação e dados de unidade da vegetação;
            <br />
            - Radiação solar e velocidade do vento;
            <br />
            - Umidade do solo </p>
        </div>
      </div>
    </div>
  )
}