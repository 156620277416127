import React from "react";
import "../../style/Preps/bannerPreps.css"

export default () => {
  return(
    <div>
      <div className="banner-preps">
        <h2>Rastreador de embarcações</h2>
      </div>
    </div>
  )
}