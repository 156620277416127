import React from "react";
import "../style/Home/Header.css";
import Logo from "../image/LogoAriasat.png";
import { useState } from "react";


export default () =>{
  const[navbar, setNavbar] = useState(false);

  const menus = document.querySelector(".menus");

          // efeitro de mudança do backgroud na rolagem
  const changeBackground = () =>  {
    if(window.scrollY >= 50){
      setNavbar(true);
    }
    else {
      setNavbar(false);
     }
  }
  window.addEventListener('scroll', changeBackground)





  return(
    <header> 
      <div className={navbar ? 'cabecalho active' : 'cabecalho'}>
        <div className="logo">
          <a href="/"><img src={Logo} alt="Logo-Ariasat" /></a>
        </div>
        <div className="menus">
          <ul>
            <a href="/Geotab"><li>Geotab</li></a>
            <a href="/Lytx"><li>Lytx</li></a>
            <a href="/Telemetria"><li>Telemetria</li></a>
            <a href="/Contato"><li className="contato">Solicite uma demo</li></a>  
          </ul>
        </div>
      </div>
    </header>
  )
}

