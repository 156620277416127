import React from "react";
import "../../style/AreaUsuario/acessoLogin.css";
import nauticoImg from "../../image/img2.png";
import { useNavigate, } from "react-router-dom";

export default () => {
  const navegate = useNavigate();

  function Nautico(){
    navegate("http://nautico.ariasat.net.br/index2.asp");
  }

  return(
    <div className="mainAcesso">
      <div className="containeer">
          <a href="http://nautico.ariasat.net.br/index2.asp" className="nautico">
            <h4>Acesso Nautico</h4>
            <div className="imagenautico">
              <img src={nauticoImg} alt="" />
            </div>     
          </a>
          <a href="http://avl.ariasat.net.br/user.asp" className="terrestre"> 
            <h4>Acesso Terrestre</h4>
              <div className="imagenautico">
                <img src={nauticoImg} alt="" />
              </div>
          </a>
        <div/>
      </div>
    </div>
  )
}