import React from "react";

import Header from "../components/Header";
import BannerLytx from "../components/lytx/bannerLytx";
import InfoLytx from "../components/lytx/infoLytx";
import RecursoBeneficio from "../components/lytx/RecursoBeneficio";

import Footer from "../components/Footer";


export default () => {
  return(
    <div>
      <Header/>
      <BannerLytx/>
      <InfoLytx/>
      <RecursoBeneficio/>    
      <Footer/>
    </div>
  )
}