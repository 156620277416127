import React from "react";
import { useState } from "react";

          //Link components
import Banner from "../components/Banner";
import Header from "../components/Header";
import InfoHome from "../components/InfoHome";
import ServicosHome from "../components/ServicosHome";
import Footer from "../components/Footer";
import Redes from "../components/Redes";
import FormularioLytx from "../components/lytx/FormularioLytx";


export default () => {

  return(
    <div>
      <Header/>
      <Banner/>
      <InfoHome/>
      <ServicosHome/>
      <Redes/>
      <FormularioLytx/>
      <Footer/>
    </div>
  )
}