import React from "react";
import "../../style/geotab/bannerGeo.css";

import BannerGeo from '../../image/bannerGeoimg.png'

export default () => {
  return(
    <div>
      <div className="bannerGeo">
        {/*<h1>Plataforma de telemetria e gerenciamento</h1>*/}
      </div>
    </div>
  )
}