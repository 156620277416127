import React from "react";
import {Route, Routes, BrowserRouter} from "react-router-dom";

import Contato from "./Screen/Contato";
import Geotab from "./Screen/Geotab";
import Home from "./Screen/Home";
import Lytx from "./Screen/Lytx";
import Preps from "./Screen/Preps";
import Telemetria from "./Screen/Telemetria";
import FormEnviado from "./Screen/FormEnviado";
import Politica from "./Screen/Politica";
import Cookie from "./components/Cookie";
import AreaUsuario from "./Screen/AreaUsuario";

import "./style/global.css";

import btnWhats from "../src/image/BtnWhats.png"
 
export default () =>{
  return( 
    <div>
      <Cookie/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/Contato" element={<Contato/>}/>
          <Route path="/Geotab" element={<Geotab/>}/> 
          <Route path="/Lytx" element={<Lytx/> } />
          <Route path="/Preps" element={<Preps/>}/>
          <Route path="/Telemetria" element={<Telemetria/>} /> 
          <Route path="/FormularioEnviado" element={<FormEnviado/>}/>
          <Route path="/politicas" element={<Politica/>}/>
          <Route path="/AreaUsuario" element={<AreaUsuario/>} />
        </Routes>
      </BrowserRouter>
      <div className="button-whats">
        <a href="https://wa.me/5511981936641" target="_blank"><img src={btnWhats} alt="Botão do WhatsApp" /></a>
      </div>
    </div>
  );
}
