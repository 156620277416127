import React from "react";
import Form from "../components/Form";
import Header from "../components/Header";
import Footer from "../components/Footer";

import "../style/Contato/Contato.css"

export default () => {
  return(
    <div className="contato-page">
      <Header/>
      <Form/>
      <Footer/>
    </div>
  )
}