import React from "react";
import "../style/Home/Banner.css";

    //imagem banner
import Banner from  "../image/banner.jpg";

export default () =>{
  return(
    <section className="featured" style={{
      backgroundSize: "cover",
      backgroundPosition: 'center',
    }}>
      <div className="featured--vertical">
        <div className="featured--horizontal">
          <div className="message-banner">
            <h1>Bem-vindo ao futuro!</h1>
            <button className="button-banner"><a href="/AreaUsuario">Area do Usuario</a></button>
          </div>
        </div>
      </div>
    </section>
  )
}

<script></script>