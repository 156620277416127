import React from "react";
import "../style/Home/Redes-resposivo.css";

import telaInicio from "../image/pub3.png";
import pub1 from  "../image/pub4.png";
import pub2 from "../image/pub2.jpg";
import blog from "../image/blog.jpg"

export default () => {
  return(
    <div>
      <div className="main-redes">
        <h3>Siga a Ariasat nas redes sociais</h3>
        <div className="image-redes">
          <a href="https://www.instagram.com/p/CjinDvkL5Xv/" target="blank"><img src={pub1} alt=""  /></a>
          <a href="https://www.instagram.com/ariaasatt/" target="blank"><img src={telaInicio} alt=""  /></a>
          <a href="https://www.instagram.com/p/CjinrPmL_l2/" target="blank"><img src={pub2} alt=""  /></a>
        </div>
      </div>
    </div>
  )
}