import React from "react";
import "../style/FormEnvio/FormEnviado.css";

import Header from "../components/Header";
import SucessoEnvio from "../components/sucessoEnvio";
import Footer from "../components/Footer";

export default () => {
  return(
    <div className="body-formEnvio">
      <Header/>
      <SucessoEnvio/>
      <Footer/>
    </div> 
  )
}