import React from "react";
import Header from "../components/Header";
import BannerPreps from "../components/Preps/bannerPreps";
import ComoFuncionaPreps from "../components/Preps/comoFuncionaPreps";
import InfoPreps from "../components/Preps/infoPreps";
import Footer from "../components/Footer";
import PorqueUtilizr from "../components/Preps/porqueUtilizar";
import PorqueUtilizar from "../components/Preps/porqueUtilizar";

export default () => {
  return(
    <div>
      <Header/>
      <BannerPreps/>
      <InfoPreps/>
      <PorqueUtilizar/>
      <ComoFuncionaPreps/>
      <Footer/>
    </div>
  )
}