import React from "react";

import "../../style/geotab/recursos.css"
import GeotabImg from "../../image/geotab.jpg"
export default () => {
  return(
    <div className="container-recursos">
      <div className="main-recursos">
        <h1>Nossos Recursos</h1>
        <div className="itens-recursos">
          <ul>
            <li>Compatibilidade com o sistema de diagnósticos da Cummins</li>
            <li>Reconstrução de acidentes;</li>
            <li>Regras customizáveis de acordo com as necessidades;</li>
            <li>Gerenciamento de rotas;</li>
            <li>Monitoramento para locadoras;</li>
            <li>SDK para integração e customização do software;</li>
            <li>Pontuação do motorista;</li>
            <li>Telemetria de empilhadeiras;</li>
            <li>Telemetria de máquinas;</li>
            <li>Veículos elétricos;</li>
          </ul>
        </div>
      </div>
      <div className="img-geotab"> <img src={GeotabImg} alt="" /> </div>
    </div>
  )
}