import React from "react";

import "../../style/Telemetria/outraSolucoes.css";

export default () => {
  return(
    <div className="main-outrasSolucoes">
      <div className="col-outrasSolucoes">
        <h4>Outras Soluções</h4>
        <ul>
          <li>Monitoramento de boias marítimas;</li>
          <li>Proteção catódica de dutos;</li>
          <li>Estações meteorológicas;</li>
          <li>Monitoramento de maquinas pesadas;</li>
          <li>Monitoramento do nível de tanques e reservatórios;</li>
          <li>Monitoração de silos e pivots de irrigação;</li>
          <li>Agricultura de precisão;</li>
          <li>Automação industrial;</li>
          <li>Hidrologia;</li>
          <li>Monitoramento de Guindastes;</li>
          <li>Medições de estações Eólicas;</li>
          <li>Qualidade de Água.</li>
        </ul>
      </div>
    </div>
  )
}